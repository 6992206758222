<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
export default {
  page: {
    title: "Payroll Reconciliation",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  async mounted() {
    this.loading = true;
    await this.getLocations();
    this.loading = false;
  },
  data() {
    return {
      title: "Payroll Reconciliation",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Payroll Reconciliation",
          active: true,
        },
      ],
      loading: false,
      payrollLocations: [],
      payrollLocation: null,
      pmyDate: null,
      fetching: false,
      newSubmitting: false,
      newPayrollLocation: null,
      newDate: null,
      reconciliations: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "sn",
      sortDesc: false,
      fields: [
        { key: "sn", label: "S/n", sortable: true },
        { key: "r_employee_t7", label: "T7", sortable: true },
        { key: "r_employee_d7", label: "D7", sortable: true },
        { key: "r_employee_name", label: "Employee Name", sortable: true },
        { key: "r_gross", label: "Gross", sortable: true },
        { key: "r_net", label: "Net", sortable: true },
        { key: "r_previous_gross", label: "Previous Gross", sortable: true },
        { key: "r_previous_net", label: "Previous Net", sortable: true },
        { key: "r_variance_gross", label: "Variance Gross", sortable: true },
        { key: "r_variance_net", label: "Variance Net", sortable: true },
      ],
      reconciliationData: {
        location: null,
        month: null,
        year: null,
      },
      updating: false,
      currentReconciliation: null,
      comment: null,
    };
  },
  methods: {
    getLocations() {
      this.apiGet(this.ROUTES.location, "Get Locations Error").then((res) => {
        this.payrollLocations = [
          { value: null, text: "Please select a location" },
        ];
        const { data } = res;
        data.forEach((location) => {
          this.payrollLocations.push({
            value: location.location_id,
            text: `${location.location_name} - (${location.l_t6_code} ) `,
          });
        });
      });
    },
    getReconciliation() {
      if (!this.pmyDate) {
        this.apiFormHandler("Payroll Period Required");
        return;
      }
      if (!this.payrollLocation) {
        this.apiFormHandler("Payroll Location Required");
        return;
      }

      this.fetching = true;

      const date = this.pmyDate.split("-");
      const data = {
        r_month: parseFloat(date[1]),
        r_year: parseFloat(date[0]),
        r_location_id: this.payrollLocation,
      };
      const url = `${this.ROUTES.salary}/pull-reconciliation`;
      this.apiPost(url, data, "Get Reconciliation Error")
        .then((res) => {
          this.reconciliations = [];
          this.reconciliationData.location = this.payrollLocations.find(
            (location) => location.value === this.payrollLocation
          ).text;
          this.reconciliationData.month = parseFloat(date[1]);
          this.reconciliationData.year = parseFloat(date[0]);
          const { data } = res;
          data.forEach((reconciliation, index) => {
            this.reconciliations[index] = { sn: ++index, ...reconciliation };
          });
          this.$refs["get-reconciliation"].hide();
        })
        .catch(() => {
          this.reconciliations = [];
          this.reconciliationData.location = null;
          this.reconciliationData.month = null;
          this.reconciliationData.year = null;
        })
        .finally(() => (this.fetching = false));
    },
    createReconciliation() {
      if (!this.newDate) {
        this.apiFormHandler("Payroll Period Required");
        return;
      }

      if (!this.newPayrollLocation) {
        this.apiFormHandler("Payroll Location Required");
        return;
      }

      this.newSubmitting = true;

      const date = this.newDate.split("-");
      const data = {
        r_month: parseInt(date[1]),
        r_year: parseInt(date[0]),
        r_location_id: parseInt(this.newPayrollLocation),
      };

      const url = `${this.ROUTES.salary}/reconciliation`;
      this.apiPost(url, data, "Create Reconciliation Error")
        .then((res) => {
          this.apiResponseHandler(res.data, "Action Successful");
        })
        .finally(() => (this.newSubmitting = false));
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    selectRow(row) {
      row = row[0];
      this.currentReconciliation = row;
      this.comment = row.r_comment;
      console.log(row);
      // this.$refs["reconciliationTable"].clearSelected();
      this.$refs["reconciliation-comment"].show();
    },
    hide() {
      this.$refs["reconciliationTable"].clearSelected();
      this.comment = null;
      this.currentReconciliation = null;
    },
    patchReconciliationComment() {
      if (!this.comment) {
        this.apiFormHandler("Comment is required");
        return;
      }

      this.updating = true;

      const data = {
        r_id: this.currentReconciliation.r_id,
        r_comment: this.comment,
      };
      const url = `${this.ROUTES.salary}/comment-reconciliation`;
      this.apiPatch(url, data, "Update Reconciliation Comment")
        .then((res) => {
          console.log(res);
          this.apiResponseHandler(res.data, "Action Successful");
          this.$refs["reconciliation-comment"].hide();
          this.getReconciliation();
        })
        .finally(() => (this.updating = false));
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-flex justify-content-end mb-3">
      <b-button
        class="btn btn-success mr-2"
        @click="$refs['get-reconciliation'].show()"
      >
        <i class="mdi mdi-plus mr-2"></i>
        Fetch Reconciliation
      </b-button>
    </div>
    <scale-loader v-if="loading" />
    <div v-else>
      <div class="row">
        <div class="col-lg-5">
          <form class="card" @submit.prevent="createReconciliation">
            <div class="card-body">
              <div class="p-3 bg-light mb-4 d-flex justify-content-between">
                <h5 class="font-size-14 mb-0">Create Payroll Reconciliation</h5>
              </div>
              <div class="form-group">
                <label for="pmy">
                  Select Payroll Period <span class="text-danger">*</span>
                </label>
                <input
                  id="pmy"
                  v-model="newDate"
                  type="month"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="">Select Location</label>
                <b-form-select
                  v-model="newPayrollLocation"
                  :options="payrollLocations"
                />
              </div>
              <b-button
                v-if="!newSubmitting"
                class="btn btn-success btn-block mt-4"
                type="submit"
              >
                Submit
              </b-button>
              <b-button
                v-else
                disabled
                class="btn btn-success btn-block mt-4"
                type="submit"
              >
                Please wait...
              </b-button>
            </div>
          </form>
        </div>
      </div>

      <div class="row" v-if="reconciliations.length">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="p-3 bg-light mb-4 d-flex justify-content-between">
                <h5 class="font-size-14 mb-0">
                  Payroll Reconciliation For
                  {{ reconciliationData.location }} during Payroll Period:
                  {{ (reconciliationData.month - 1) | getMonth }}
                  {{ reconciliationData.year }}
                </h5>
              </div>
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select
                      >&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-right"
                  >
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <!-- Table -->
              <div class="table-responsive mb-0">
                <b-table
                  ref="reconciliationTable"
                  bordered
                  selectable
                  hover
                  :items="reconciliations"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  show-empty
                  select-mode="single"
                  @row-selected="selectRow"
                >
                  <template #cell(r_gross)="row">
                    <p class="float-right mb-0">
                      {{ parseFloat(row.value.toFixed(2)).toLocaleString() }}
                    </p>
                  </template>
                  <template #cell(r_net)="row">
                    <p class="float-right mb-0">
                      {{ parseFloat(row.value.toFixed(2)).toLocaleString() }}
                    </p>
                  </template>
                  <template #cell(r_previous_gross)="row">
                    <p class="float-right mb-0">
                      {{ parseFloat(row.value.toFixed(2)).toLocaleString() }}
                    </p>
                  </template>
                  <template #cell(r_previous_net)="row">
                    <p class="float-right mb-0">
                      {{ parseFloat(row.value.toFixed(2)).toLocaleString() }}
                    </p>
                  </template>
                  <template #cell(r_variance_gross)="row">
                    <p class="float-right mb-0">
                      {{ parseFloat(row.value.toFixed(2)).toLocaleString() }}
                    </p>
                  </template>
                  <template #cell(r_variance_net)="row">
                    <p class="float-right mb-0">
                      {{ parseFloat(row.value.toFixed(2)).toLocaleString() }}
                    </p>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="get-reconciliation"
      title="Fetch Payroll Reconciliation"
      hide-footer
      centered
      title-class="font-18"
    >
      <form @submit.prevent="getReconciliation">
        <div class="form-group">
          <label for="pmy">
            Select Payroll Period <span class="text-danger">*</span>
          </label>
          <input id="pmy" v-model="pmyDate" type="month" class="form-control" />
        </div>
        <div class="form-group">
          <label for="">Select Location</label>
          <b-form-select
            v-model="payrollLocation"
            :options="payrollLocations"
          />
        </div>
        <b-button
          v-if="!fetching"
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submit
        </b-button>
        <b-button
          v-else
          disabled
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submitting...
        </b-button>
      </form>
    </b-modal>

    <b-modal
      ref="reconciliation-comment"
      title="Reconciliation Details"
      hide-footer
      centered
      title-class="font-18"
      @hidden="hide"
    >
      <form
        @submit.prevent="patchReconciliationComment"
        v-if="currentReconciliation"
      >
        <p class="mb-0">Name: {{ currentReconciliation.r_employee_name }}</p>
        <p class="mb-0">T7: {{ currentReconciliation.r_employee_t7 }}</p>
        <p class="mb-0">D7: {{ currentReconciliation.r_employee_d7 }}</p>
        <p class="mb-0">
          Gross:
          {{
            parseFloat(
              currentReconciliation.r_gross.toFixed(2)
            ).toLocaleString()
          }}
        </p>
        <p class="mb-0">
          Net:
          {{
            parseFloat(currentReconciliation.r_net.toFixed(2)).toLocaleString()
          }}
        </p>
        <p class="mb-0">
          Previous Gross:
          {{
            parseFloat(
              currentReconciliation.r_previous_gross.toFixed(2)
            ).toLocaleString()
          }}
        </p>
        <p class="mb-2">
          Previous Net:
          {{
            parseFloat(
              currentReconciliation.r_previous_net.toFixed(2)
            ).toLocaleString()
          }}
        </p>
        <div class="form-group">
          <label for="comment"> Comment </label>
          <textarea
            id="comment"
            v-model="comment"
            class="form-control"
            :readonly="currentReconciliation.r_comment"
          />
        </div>
        <div v-if="!currentReconciliation.r_comment">
          <b-button
            v-if="!updating"
            class="btn btn-success btn-block mt-4"
            type="submit"
          >
            Submit
          </b-button>
          <b-button
            v-else
            disabled
            class="btn btn-success btn-block mt-4"
            type="submit"
          >
            Submitting...
          </b-button>
        </div>
      </form>
    </b-modal>
  </Layout>
</template>
